import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import LayoutLanding from '../../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { SeoData } from '../../../../src/common/types/Seo'
import { locale, t } from '../../../../src/common/i18n'
import LandingPatinete from '../../static/landing-patinete'
import { getImages } from '../../../../src/common/utils/imagesS3'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/pages/landing.module.scss'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../constants/menu'
const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/850x200?text=Placeholder+Image',
  link: '',
  alt: '',
}

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  const [banners_mobile] = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [banners_desktop] = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.patinete'),
        },
      ]}>
      <LayoutLanding
        title={t('layout_patinete_title')}
        imageDesktop={banners_desktop}
        imageMobile={banners_mobile}
        descriptionCTA={t('CTA.patinete')}
        textButtonCTA={t('ficha-taller.appointment')}
        linkButtonCTA={route('cita-previa.marca_patinete')}
        ctaLink={{
          cta: 'cita_previa_patinete',
          cta_es: 'Solicitar cita previa',
          cta_ca: 'Demanar cita prèvia',
        }}>
        <LandingPatinete styles={styles} data={data} />
      </LayoutLanding>
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingPatineteQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    imagenes_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/imagenes/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/imagenes/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/imagenes/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/imagenes/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/banners/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/banners/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/banners/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/tipo-vehiculo/patinete/banners/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
