import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingPatinetCa = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
        Els patinets elèctrics, també anomenats Vehicles de Mobilitat Personal (VPM),
         han aconseguit convertir-se en una nova realitat als carrers de les nostres ciutats.
         Es caracteritzen per ser <strong>vehículos unipersonales 100% eléctricos respetuosos con el medio ambiente</strong>
         pel que fa a l’emissió de gasos d'efecte d'hivernacle.
        </p>
        <p className={styles.main_description}>
        Actualment al mercat existeix una amplíssima varietat d'aquests vehicles amb diferents mesures i prestacions.
         Tots ells, segons recull la DGT,
         estan dissenyats per a aconseguir una <strong>velocitat màxima situada entre els 6 i els 25 Km/h.  </strong>
         Aquests vehicles també poden comptar amb un seient, encara que el més habitual és que el conductor es desplaci dempeus sobre el patinet. 
        </p>
        <section className={styles.section}>
          <h2>
          Quin tipus de manteniment requereix un patinet elèctric?
          </h2>
          <p>
          Com qualsevol altre vehicle, els patinets elèctrics també requereixen un manteniment i una certa posada a punt de manera periòdica.
           El manteniment d'aquests vehicles és relativament poc, els més freqüents són:
          </p>
          <ul>
            <li>Canvi de Pneumàtics i cambra del pneumàtic</li>
            <li>Reparació de punxades</li>
            <li>Substitució de les pastilles de fre</li>
            <li>Tibat de cadena o corretja</li>
          </ul>
          <p className={styles.main_description}>
          A Rodi Motor Services podem ajudar-te amb diversos d'aquests serveis pel teu patinet elèctric. Demana cita prèvia sense compromís i t'assessorarem sobre quina solució és la que necessita el teu vehicle. 
          </p>
        </section>
        
        <section className={styles.section}>
          <h2>Consells per a disposar d'un Patinet elèctric en perfectes condicions.</h2>
          <p>
          Si vols que el teu patinet elèctric et duri molt temps, t'aconsellem que, a part del seu manteniment periòdic, tinguis especial compte amb els següents aspectes:
          </p>
          <ul>
            <li>
            <strong>Neteja regularment el teu patinet elèctric.</strong> El patinet, com tot vehicle, de circular per la ciutat acumula pols i brutícia. Tracta de mantenir-ho net, això ajudarà a conserva millor els seus components mecànics i el protegirà de la corrosió.
            </li>
            <li>
            <strong>Comprova el motor i la correcta tensió de la cadena o corretja.</strong> La cadena del patinet a vegades requereix ser tibada, això garanteix un correcte lliurament de la potència del vehicle.
            </li>
            <li>
              <strong>Utilitza sempre el transformador proporcionat pel fabricant per fer les recàrregues de bateria.</strong> Fer ús de carregadors alternatius pot no ser convenient per les característiques del teu patinet. A vegades les diferències de voltatge, amperatge i polaritat poden no ser adequades per la bateria i danyar la seva capacitat de contenir energia.
               Per a evitar possibles defectes, fes ús sempre del carregador oficial.
            </li>

          </ul>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Quins Pneumàtics necessita el meu Patinet Elèctric?</h2>
          <p>
          Com has pogut comprovar, el patinet elèctric compta amb diversos tipus de manteniments. Un dels més importants és sens dubte el dels pneumàtics. Aquest component és el responsable de mantenir el teu patinet en contacte amb el terra. És essencial per a la teva seguretat que
          <strong> els pneumàtics es trobin en perfecte estat i que s'adaptin a les característiques del teu patinet.</strong>
          </p>
          <p>
          Actualment existeixen <strong>3 tipus de pneumàtics </strong> que trobaràs a Rodi Motor Services disponibles pel teu patinet elèctric. Aquests són: 
          </p>
          <ul>
            <li>
            <strong>Pneumàtics amb Cambra:</strong> son els més freqüent.
             És un pneumàtic clàssic, que disposa d'una cambra interior que s'encarrega de mantenir la pressió.
              En cas de punxada pot ser necessari canviar el pneumàtic o simplement la seva cambra interior.
               És important portar-los sempre amb la pressió d'aire correcta perquè no es produeixin pessics amb la llanda.
             Per a això, es recomana portar un control de pressions de manera regular. 
            </li>
            <li>
            <strong>Pneumàtics Tubeless:</strong> són pneumàtics que no compten amb cambra i que mantenen les pressions gràcies a un líquid sallant que hi ha al seu interior. Gràcies a això, la quantitat d'aire és més estable i es redueixen considerablement les micro fuges degudes al pas del temps. No requereixen inflat amb tanta freqüència, però sí d'un model de llanta específic per aquest pneumàtic. 
            </li>
            <li>
              <strong>Pneumàtics Massissos:</strong> aquests pneumàtics són rígids i estan fabricats amb una goma massissa que es desgasta amb el pas del temps. Destaquen en què eliminen la cambra del pneumàtic i, per tant, no existeix risc de punxada amb ells. Tot i això, no són tan efectius com els tradicionals en qüestió d'estabilitat i absorció de vibracions. A més, perden adherència a mesura que la goma es desgasta i perd elasticitat, sobretot sobre paviment mullat.
            </li>
          </ul>
          <p className={styles.main_description}>
          A Rodi Motor Services trobaràs <strong>els pneumàtics pel teu patinet al millor preu.</strong> Demana cita prèvia al teu taller més proper i el nostre equip especialitzat s'encarregarà d'assessorar-te en el manteniment adequat pels pneumàtics del teu patinet elèctric.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingPatinetEs = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
        Los patinetes eléctricos, también llamados Vehículos de Movilidad Personal (VPM),
         han conseguido convertirse en una nueva realidad en las calles de nuestras ciudades.
         Se caracterizan por ser <strong>vehículos unipersonales 100% eléctricos respetuosos con el medio ambiente</strong>
          en lo referido a emisión de gases de efecto invernadero.
        </p>
        <p className={styles.main_description}>
        Actualmente en el mercado existe una amplísima variedad de estos vehículos con diferentes medidas y prestaciones. Todos ellos, según recoge la DGT, están diseñados para alcanzar
         una <strong>velocidad máxima situada entre los 6 y los 25 Km/h. </strong>
         Estos vehículos también pueden contar con un sillín o asiento, aunque lo más habitual es 
         que el conductor se desplace de pie sobre el patinete. 
        </p>
        <section className={styles.section}>
          <h2>
          ¿Qué tipo de mantenimiento requiere un patinete eléctrico?
          </h2>
          <p>
          Como cualquier otro vehículo, los patinetes eléctricos también requieren un mantenimiento y cierta puesta a punto de forma periódica. 
          El mantenimiento de estos vehículos es relativamente poco, los más frecuentes son:
          <ul>
            <li>Cambio de Neumáticos y cámara del neumático.</li>
            <li>Reparación de Pinchazos en los neumáticos</li>
            <li>Sustitución de las pastillas de freno. </li>
            <li>Tensado de cadena o correa </li>
          </ul>
          </p>
          <p className={styles.main_description}>
          En tu taller Rodi Motor Services podemos ayudarte con varios de estos servicios para tu patinete eléctrico. Solicita cita previa sin compromiso y te asesoraremos sobre qué solución es la que necesita tu vehículo. 
          </p>
        </section>
        
        <section className={styles.section}>
          <h2>Consejos para disponer de un Patinete eléctrico en perfectas condiciones. </h2>
          <p>
          Si quieres que tu patinete eléctrico te dure mucho tiempo, te aconsejamos que, a parte de su mantenimiento periódico, tengas un especial cuidado con los siguientes aspectos: 
          </p>
          <ul>
            <li>
            <strong>Limpia tu patinete eléctrico de restos de polvo y suciedad.</strong> El patinete, como todo vehículo, de circular por la ciudad acumula polvo y suciedad. Tratar de mantenerlo limpio ayudará a conserva mejor sus componentes mecánicos y lo protegerá de la corrosión. 
            </li>
            <li>
            <strong>Comprueba el motor y la correcta tensión de la cadena o correa.</strong> La cadena del patinete en ocasiones requiere ser tensada, esto garantiza una correcta entrega de la potencia del vehículo. 
            </li>
            <li>
              <strong>Utiliza siempre el transformador proporcionado por el fabricante para las recargas de batería.</strong> Hacer uso de cargadores alternativos puede no ser conveniente para las características de tu patinete. En ocasiones las diferencias de voltaje, amperaje y polaridad pueden no ser adecuadas y dañar la capacidad de este componente tan delicado. Para evitar posibles defectos, haz uso siempre del cargador oficial.
            </li>

          </ul>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>¿Qué Neumáticos necesita mi Patinete Eléctrico?</h2>
          <p>
          Como has podido comprobar, el patinete eléctrico cuenta con diversos tipos de mantenimiento. Uno de los más importantes es sin duda el de los neumáticos. Este componente es el responsable de mantener tu patinete en contacto con el suelo. Es esencial para tu seguridad que los
          <strong> neumáticos se encuentren en perfecto estado y que se adapten a las características de tu patinete.</strong>
          <p>
          Actualmente existen <strong>3 tipos de neumáticos que encontrarás en Rodi Motor Services disponibles para tu patinete eléctrico.</strong> Estos son: 
          </p>
          </p>
          <ul>
            <li>
            <strong>Neumáticos con cámara</strong> el tipo de neumático más frecuente.
             Es el tipo de neumático clásico que dispone de una cámara interior que se encarga de mantener la presión.
              En caso de pinchazo puede ser necesario cambiar el neumático o simplemente su cámara interior.
               Es importante llevarlos siempre con la presión de aire correcta para que no se produzcan pellizcos con la llanta.
             Para ello, se recomienda llevar un control de presiones de forma regular. 
            </li>
            <li>
            <strong>Neumáticos Tubeless</strong> son neumáticos que no cuentan con cámara y que mantienen las presiones gracias a un líquido sellante que contienen en su interior. Gracias a ello la cantidad de aire es más estable y se reducen considerablemente las micro fugas debidas al paso del tiempo. No requieren inflar el neumático con tanta frecuencia, pero sí de un modelo de llanta específico para ellos. 
            </li>
            <li>
              <strong>Neumáticos Macizos</strong> estos neumáticos son rígidos y están fabricados con una goma maciza que se desgasta con el paso del tiempo. Destacan en que eliminan la cámara del neumático y, por tanto, no existe riesgo de pinchazo con ellos. Sin embargo, no son tan efectivos como los tradicionales en cuestión de estabilidad y absorción de vibraciones. Además, pierden adherencia a medida que la goma se desgasta y pierde elasticidad, sobre todo sobre pavimento mojado. 
            </li>
          </ul>
          <p className={styles.main_description}>
          En talleres Rodi Motor Services encontrarás los <strong>neumáticos para tu patinete al mejor precio.</strong> Solicita cita previa en tu taller más cercano y nuestro equipo especializado se encargará de asesorarte con el mantenimiento de los neumáticos de tu patinete eléctrico. 
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingPatinet = ({ ...props }: Props) =>
  locale === 'es' ? <LandingPatinetEs {...props} /> : <LandingPatinetCa {...props} />

export default LandingPatinet
